/* Custom Stylesheet
 * Version: 1.0.0
 *
 * Coded by CoveTech.net
 */

.displayNone {
    display: none;
}

.has-accent-1-background div div h2 {
    color: white;
}

.has-accent-1-background div div .ptf-approach-box {
    color: white;
    border-top: 2px solid white;
}

.has-accent-1-background div div .ptf-approach-box .ptf-approach-box__subtitle {
    color: white;
}

.has-accent-1-background div div .ptf-approach-box .ptf-approach-box__content p {
    color: white;
}

/* Font Sizes */

.font-size-40 {
    font-size: 40px;
}

.font-size-24 {
    font-size: 24px;
}

.css-dip3t8 {
    background-color: transparent !important;
    border-color: transparent !important;
}

.css-1wvake5 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: none;
    z-index: 3;
    background-color: transparent !important;
    border-color: transparent !important;
}

.ps-menu-label.css-12w9als {
    font-weight: bold;
    font-size: 1.4rem;
    color: #222;
}

.ps-menu-label.css-12w9als:hover {
    font-weight: bold;
    font-size: 1.4rem;
    color: #0d6efd;
}

.menu-header {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    margin-top: 10%;
    margin-bottom: 10px;
}

.menu-links {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
}

.mt-10 {
    margin-top: 10px;
}

.displayNone,
.display-none {
    display: none;
}

.client-logo-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}